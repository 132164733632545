<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Inscrição</a>
        </li>
      </ul>
    </nav>

    <b-loading :is-full-page="true" :active.sync="load" :can-cancel="false"></b-loading>

    <button
      class="button m-b-md"
      @click="isCardModalActive2 = true, inscricao = {}, editEnroll = false"
    >Adicionar</button>

    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="getEnroll">
          <b-field label="Grupo">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">{{team.name}}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Data de Inicio">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.initDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de Fim">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.endtDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de validade">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.validity"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <!-- <b-field label="Login">
            <Multiselect
              v-model="query.login"
              id="ajax2"
              label="username"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList2"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch2"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field> -->

          <b-field label="Curso">
            <b-select v-model="query.curso" placeholder="Curso">
              <option v-for="course in cursos" :key="course._id" :value="course._id">{{course.name}}</option>
              <option :value="undefined">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Meio de pagamento">
            <b-select v-model="query.paymentMethod" placeholder="Curso">
              <option value="contrato">Contrato</option>
              <option value="bonus">Bônus</option>
              <option value="boleto">Boleto</option>
              <option value="deposito">Depósito</option>
              <option value="transferencia">Transferência</option>
              <option value="teste">Teste</option>
              <option :value="undefined">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <button class="button is-primary">Buscar</button>
          <button class="button is-danger m-l-sm" @click="(query = {}), (users = [])">Limpar</button>
        </form>
      </div>
    </b-collapse>

    <h1>Total: {{total}}</h1>
    <div class="export">
      <button class="button is-primary" @click="planilha">Exportar</button>
    </div>

    <b-table
      :data="filter"
      :paginated="false"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="user.first_name"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot-scope="props">
        <b-table-column field="user.name" label="Nome" sortable>{{ props.row.user && props.row.user.name ? props.row.user.name : '' }}</b-table-column>

        <b-table-column field="user.username" label="Login" sortable>{{ props.row.user ? props.row.user.username : '' }}</b-table-column>

        <b-table-column
          field="paymentMethod"
          label="Tipo de inscrição"
          sortable
        >{{ props.row.paymentMethod }}</b-table-column>

        <b-table-column field="user.team.name" label="Grupo" sortable>{{ props.row.user && props.row.user.teams && props.row.user.teams[0] ? props.row.user.teams[0].name : '' }}</b-table-column>

        <b-table-column field="course.name" label="Curso" sortable>{{ props.row.course.name }}</b-table-column>

        <b-table-column field="createdAt" label="Data da inscrição" numeric centered sortable>
          <span
            class="tag is-success"
          >{{ new Date(props.row.createdAt).toLocaleDateString("pt-BR") }}</span>
        </b-table-column>

        <b-table-column field="validityTime" label="Data de validade" numeric centered sortable>
          <span
            class="tag is-success"
          >{{ props.row.validityTime ? new Date(props.row.validityTime).toLocaleDateString("pt-BR") : '' }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button @click="editEn(props)" class="button is-primary m-r-sm">Editar</b-button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <div class="pagination-custom">
      <b-pagination
        class="m-t-md"
        :total="total"
        :current.sync="current"
        :simple="false"
        :rounded="false"
        :per-page="perPage"
        aria-next-label="Próxima pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Page"
        aria-current-label="Pagina atual"
      ></b-pagination>
    </div>

    <b-modal :active.sync="isCardModalActive2" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-on:submit.prevent="addIncricao">
              <b-field label="Validade da inscrição">
                <b-datepicker
                  :month-names="datePicker[0]"
                  :day-names="datePicker[1]"
                  icon="calendar-alt"
                  v-model="inscricao.validityTime"
                ></b-datepicker>
              </b-field>

              <b-field label="Curso" v-if="!editEnroll" required>
                <b-select v-model="inscricao.course" placeholder="Curso">
                  <option v-for="curso in cursos" :key="curso._id" :value="curso._id">{{curso.name}}</option>
                </b-select>
              </b-field>

              <!-- <b-field v-if="!editEnroll" label="Usuário" required>
                <b-select v-model="inscricao.user" placeholder="Curso">
                  <option v-for="user in users" :key="user._id" :value="user._id">{{user.name}}</option>
                </b-select>
              </b-field>-->

              <b-field label="Usuário" v-if="!editEnroll">
                <Multiselect
                  v-model="user"
                  id="ajax"
                  label="name"
                  track-by="name"
                  placeholder="Digite para pesquisar"
                  open-direction="bottom"
                  :options="searchList"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoadingMultiSelect"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  :preselect-first="false"
                  @search-change="getBySearch"
                  selectLabel="Pressione enter para selecionar"
                  deselectLabel="Pressione enter para desmarcar"
                  selectedLabel="Selecionado"
                >
                  <template slot="noOptions">Lista vazia</template>
                </Multiselect>
              </b-field>

              <b-field label="Tipo de Pagamento" required>
                <b-select v-model="inscricao.paymentMethod" placeholder="Tipo de Pagamento">
                  <option value="contrato">Contrato</option>
                  <option value="bonus">Bônus</option>
                  <option value="boleto">Boleto</option>
                  <option value="deposito">Depósito</option>
                  <option value="transferencia">Transferência</option>
                  <option value="teste">Teste</option>
                </b-select>
              </b-field>

              <div class="field">
                <b-checkbox v-model="inscricao.active" size="is-small">Ativo</b-checkbox>
              </div>

              <!-- <b-field label="Valor pago">
                <b-input v-model="inscricao.price"></b-input>
              </b-field>-->

              <!-- <b-field label="Observação">
                <b-input v-model="inscricao.note" type="textarea"></b-input>
              </b-field>-->

              <button class="button is-info m-t-md">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Axios from "axios";
import { debounce } from "lodash";

import Multiselect from "vue-multiselect";
import XLSX from "xlsx";
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      isCardModalActive2: false,
      isLoadingMultiSelect: false,
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ],
      inscricao: {
        course: "",
        price: "",
        payment: "",
        note: "",
        active: true,
        validityTime: null
      },
      data: [],
      cursos: [],
      users: [],
      searchList: [],
      searchList2: [],
      editEnroll: false,
      search: null,
      total: "",
      current: 1,
      perPage: "",
      query: {},
      user: [],
      teams: [],
      load: null
    };
  },
  methods: {
    async planilha() {
      // let wb = XLSX.utils.table_to_book(document.getElementsByClassName("table"));

      let sunrise, sunset, validity;
      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setHours(0);
        sunrise.setMinutes(0);
        sunrise.setSeconds(0);
        sunrise.toISOString();
      }

      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setHours(23);
        sunset.setMinutes(59);
        sunset.setSeconds(59);
        sunset.toISOString();
      }

      if (this.query.validity) {
        validity = new Date(this.query.validity);
        validity.setHours(23);
        validity.setMinutes(59);
        validity.setSeconds(59);
        validity.toISOString();
      }

      this.load = true;
      let status = [];
      let vezes = Math.round(this.total / 1000);
      if (this.total > 1000 && vezes === 1) vezes = 2;
      if (this.total > 0 && this.total <= 1000) vezes = 1;

      for (let index = 0; index < vezes; index++) {
        let inscription = await Axios.get(
          `/v1/enrolls?limit=1000&page=${index + 1}${
            this.users._id ? '&user=' + this.users._id : ""
          }${this.query.team ? '&team=' + this.query.team : ""}${
            this.query.curso ? '&course=' + this.query.curso : ""
          }${
            this.query.paymentMethod ? '&paymentMethod=' + this.query.paymentMethod : ""
          }${this.query.initDate ? "&createdAt[$gte]=" + sunrise : ""}${
            this.query.endtDate ? "&createdAt[$lte]=" + sunset : ""
          }${this.query.validity ? "&validityTime[$lte]=" + validity : ""}`
        );

        for (const iterator of inscription.data.data) {
          status.push(iterator);
        }
      }

      status = status.map(item => {
        return {
          nome: item.user ? item.user.name : "",
          login: item.user ? item.user.username : "",
          tipo_de_inscricao: item.paymentMethod,
          // grupo: item.user ? item.user.team.name : "",
          Curso: item.course.name,
          Data_da_inscricao: new Date(item.createdAt).toLocaleDateString(
            "pt-BR"
          ),
          Data_da_validade: new Date(item.validityTime).toLocaleDateString(
            "pt-BR"
          )
        };
      });

      let ws = XLSX.utils.json_to_sheet(status);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      XLSX.writeFile(wb, "report.xlsx");
      this.load = null;
    },
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(`/v1/users?name=${arguments[0]}`)
          .then(res => {
            this.searchList = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    getBySearch2: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${
            this.me ? 'users/team/' + this.me.teams[0] : 'users'
          }?username=${arguments[0]}`
        )
          .then(res => {
            this.searchList2 = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    editEn(data) {
      this.inscricao.course = data.row.courseid;
      this.inscricao.user = data.row.userid;
      this.inscricao.validityTime = data.row.validityTime
        ? new Date(data.row.validityTime)
        : null;
      this.inscricao.paymentMethod = data.row.paymentMethod
        ? data.row.paymentMethod
        : null;
      this.inscricao.active = data.row.active;
      this.editEnroll = data.row._id;
      this.isCardModalActive2 = true;
    },
    remove(data) {
      Axios.delete(`/v1/enrolls/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getEnroll();
            this.$buefy.notification.open({
              message: "Inscrição excluida com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getEnroll() {
      let sunrise, sunset, validity;
      if (this.query.initDate) {
        sunrise = new Date(this.query.initDate);
        sunrise.setHours(0);
        sunrise.setMinutes(0);
        sunrise.setSeconds(0);
        sunrise.toISOString();
      }

      if (this.query.endtDate) {
        sunset = new Date(this.query.endtDate);
        sunset.setHours(23);
        sunset.setMinutes(59);
        sunset.setSeconds(59);
        sunset.toISOString();
      }

      if (this.query.validity) {
        validity = new Date(this.query.validity);
        validity.setHours(23);
        validity.setMinutes(59);
        validity.setSeconds(59);
        validity.toISOString();
      }

      Axios.get(
        `/v1/enrolls?page=${this.current}${
            this.users._id ? '&user=' + this.users._id : ""
          }${this.query.team ? '&team=' + this.query.team : ""}${
            this.query.curso ? '&course=' + this.query.curso : ""
          }${
            this.query.paymentMethod ? '&paymentMethod=' + this.query.paymentMethod : ""
          }${this.query.initDate ? "&createdAt[$gte]=" + sunrise : ""}${
            this.query.endtDate ? "&createdAt[$lte]=" + sunset : ""
          }${this.query.validity ? "&validityTime[$lte]=" + validity : ""}`
      )
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            this.total = data.data.total;
            this.perPage = data.data.limit;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> esta inscrição?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    addIncricao() {
      let req;
      if (this.editEnroll) {
        req = Axios.put(`/v1/enrolls/${this.editEnroll}`, this.inscricao);
      } else {
        this.inscricao.user = this.user._id;
        req = Axios.post(`/v1/enrolls/`, this.inscricao);
      }
      req
        .then(data => {
          if (data.data) {
            this.edit
              ? this.$buefy.notification.open({
                  message: "Inscrição adicionada com sucesso!",
                  type: "is-success"
                })
              : this.$buefy.notification.open({
                  message: "Inscrição atualizada com sucesso!",
                  type: "is-success"
                });

            this.edit = false;
            this.inscricao = {};
            this.getEnroll();
            this.isCardModalActive2 = false;
          }
        })
        .catch(erro => {
          this.edit = false;
          if (
            erro.response.data.message.includes(
              "There is already an inscription for this user in this course"
            )
          ) {
            this.$buefy.dialog.alert({
              title: "OPPS!",
              message: "O usário já está inscrito nesse curso!",
              type: "is-danger",
              hasIcon: true,
              iconPack: "fa"
            });
          }
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then(data => {
          if (data.data) {
            this.cursos = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then(data => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  watch: {
    current(current) {
      this.getEnroll(current);
    }
  },
  computed: {
    filter: function() {
      if (!this.data.length) {
        return [];
      }

      return this.data.filter(item => {
        let props = Object.values(item);
        let search = new RegExp(this.search, "i");
        return props.some(
          prop =>
            !this.search ||
            (typeof prop === "string"
              ? prop.match(search)
              : prop.toString(10).match(search))
        );
      });
    }
  },
  mounted() {
    this.getEnroll();
    this.getCourse();
    this.getTeam();
  }
};
</script>

<style lang="scss" scoped>
.is-flex-desktop {
  .field {
    width: 100%;
  }
}
img {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 10px;
}
.export {
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
}
</style>
<style lang="scss">
.multiselect__content {
  list-style: none !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}
</style>
